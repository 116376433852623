import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form"

const FormError = (props)=> {
  // console.log("error", props.message)
  return (
    <div className="
      relative
      box-border
      text-xs
      font-normal
      leading-[1.4]
      pl-3
      mt-0
      overflow-hidden
      text-[#ff3b30]
    ">
      {props.message}
    </div>
  )
}
export default FormError