import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

export const NotFound = () => { 
  const navigate = useNavigate();

  const handleZoomStart = () => {
    navigate('/');
  }

  return(
    <section
      className="
        fixed
        top-0
        left-0
        flex
        flex-col
        w-screen
        h-screen
        bg-[url('https://www.worokas.com/images/404/bg.jpg')]
        bg-no-repeat
        bg-cover
        bg-center
        items-center 
        justify-center
      "
    >
      <div className="mt-0 mx-auto max-w-[360px] text-center">
        <h1 class="text-white text-[25px] text-center font-bold tracking-[6px] leading-normal">404 NOT FOUND</h1>
        <button
          className="
            bg-[#ae1f00] 
            hover:bg-[#E02500]
            text-gray-100
            hover:text-white
            font-bold 
            py-3 
            px-4 
            rounded-md
            flex 
            justify-center 
            items-center 
            cursor-pointer
            w-full
            h-[44px]
            duration-200
            ease-in
          "
          onClick={handleZoomStart}
        >
          Sign up with Zoom
        </button>
      </div>
      
    </section>
  )
}