import { useState, useLayoutEffect, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { ZoomMtg } from '@zoomus/websdk';
import '@zoomus/websdk/dist/css/bootstrap.css'
import '@zoomus/websdk/dist/css/react-select.css'
import Input from '../../input';
import FormError from '../../form/error';
import ProgressIndicator from '../../progressIndicator/';
import DiaLog from '../../dialog/';
import './dashboard.css'

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.14.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('jp-JP');
ZoomMtg.i18n.reload('jp-JP');

export const Dashboard = () => {
  const navigate = useNavigate();
  const methods = useForm({mode: 'all',});
  const [isEmailFocus, setIsEmailFocus] = useState(false);
  const [isDisplayNameFocus, setIsDisplayNameFocus] = useState(false);
  const [isEmailValidate, setIsEmailValidate] = useState(false);
  const [isDisplayNameValidate, setIsDisplayNameValidate] = useState(false);
  const [createError, setCreateError] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [progressIndicator, setProgressIndicator] = useState(false);
  const [presentDialog, setPresentDialog] = useState({status: false, header: '', subHeader: '', message: '', buttons: []});

  let user = {email: '', displayName: ''}

/*
  const getAccessToken = async (code) => {
    console.log('code', code)
    const queries = { code: code };
    await axios
    .get('https://zoom.worokas.com/api/auth/token/', {params: queries})
*/
  const getAccessToken = async () => {
    await axios
    .get('https://zoom.worokas.com/api/token/')
    .then(response => {
      setProgressIndicator(false);
      setAccessToken(response.data.access_token)
      // setRefreshToken(response.data.refresh_token)
      // getUser(response.data.access_token, response.data.refresh_token)
      // getUser(response.data.access_token, response.data.refresh_token)
    })
    .catch(error => {
      setProgressIndicator(false);
      setPresentDialog({
        status: true,
        header: 'エラー',
        subHeader: `アクセストークンの取得に失敗しました。`,
        message: 'サインインへリダイレクトします。',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: ()=> {
              setTimeout(()=>{
                setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                // navigate('/signin/')
              }, 300)
            }
          },
        ]
      });
      console.log('error', error);
    });
  }

  const getUser = async (accessToken, refreshToken) => {
    const queries = { 
      accessToken: accessToken,
      refreshToken: refreshToken
    };
    await axios
    .get('https://zoom.worokas.com/api/user/', {params: queries})
    .then(response => {
      setProgressIndicator(false);
      if(response.data.id){
        // setUser(response.data);
      } else {
        setPresentDialog({
          status: true,
          header: 'エラー',
          subHeader: `ユーザー情報の取得に失敗しました。`,
          message: 'サインインへリダイレクトします。',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: ()=> {
                setTimeout(()=>{
                  setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                  // navigate('/signin/')
                }, 300)
              }
            },
          ]
        });
      }
    })
    .catch(error => {
      setProgressIndicator(false);
      setPresentDialog({
        status: true,
        header: 'エラー',
        subHeader: `ユーザー情報の取得に失敗しました。`,
        message: 'サインインへリダイレクトします。',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: ()=> {
              setTimeout(()=>{
                setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                // navigate('/signin/')
              }, 300)
            }
          },
        ]
      });
      console.log('error', error, queries);
    });
  }

  const createMeeting = async (data) => {
    setProgressIndicator(true);
    user = { email: data.email, displayName: data.displayName };
    const queries = {
      // id: user.id,
      id: 'E9ZzRxcMSDKcErv_T7tnmQ',
      accessToken: accessToken,
    };
    await axios
    .get('https://zoom.worokas.com/api/meeting/create/', {params: queries})
    .then(response => {
      getSignature(response.data.id, response.data.password);
    })
    .catch(error => {
      setProgressIndicator(false);
      setPresentDialog({
        status: true,
        header: 'エラー',
        subHeader: `ミーティングの作成に失敗しました。`,
        message: 'サインインへリダイレクトします。',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: ()=> {
              setTimeout(()=>{
                setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                // navigate('/signin/')
              }, 300)
            }
          },
        ]
      });
      console.log('error', error, queries);
    });
  }

  const getSignature = async (meetingNumber, password) => {
    console.log('user', user);
    const queries = {
      meetingNumber: meetingNumber,
      role: 1,
      accessToken: accessToken,
      email: user.email,
      displayName: user.displayName
    };
    await axios
    .get('https://zoom.worokas.com/api/meeting/signature/', {params: queries})
    .then(response => {
      console.log('response.data', response.data)
      startMeeting(response.data.signature, meetingNumber, password)
    })
    .catch(error => {
      setProgressIndicator(false);
      setPresentDialog({
        status: true,
        header: 'エラー',
        subHeader: `ミーティング認証の取得に失敗しました。`,
        message: 'サインインへリダイレクトします。',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: ()=> {
              setTimeout(()=>{
                setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                // navigate('/signin/')
              }, 300)
            }
          },
        ]
      });
      console.log('error', error, queries);
    });
  }

  const startMeeting = (signature, meetingNumber, password) => {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: process.env.REACT_APP_ZOOM_MEETING_LEAVE_URL,
      success: (success) => {
        setProgressIndicator(false);
        ZoomMtg.join({
          signature: signature,
          sdkKey: process.env.REACT_APP_ZOOM_MEETING_SDK_KEY,
          meetingNumber: meetingNumber,
          passWord: password,
          userName: user.displayName,
          userEmail: user.email,
          tk: '',
          zak: '',
          success: (success) => {
            const footer = document.getElementById('foot-bar');
            const fireDiv = document.createElement('div');
            const fireInput = document.createElement('input');
            const fireBtn = document.createElement('button');
            fireDiv.id = 'fireDiv';
            fireInput.id = 'fireInput'
            fireInput.setAttribute('type', 'text');
            fireInput.setAttribute('size', '10');
            fireInput.placeholder = 'お二人へのメッセージを入力';
            fireBtn.id = 'fireBtn';
            fireBtn.textContent = '弾幕';
            fireBtn.onclick = () => {
              // showChatPane()
              fire()
            }
            fireDiv.appendChild(fireInput);
            fireDiv.appendChild(fireBtn);
            footer.appendChild(fireDiv);
          },
          error: (error) => {
            setProgressIndicator(false);
            console.log(error)
          }
        })

      },
      error: (error) => {
        setPresentDialog({
          status: true,
          header: 'エラー',
          subHeader: `ミーティングの開始に失敗しました。`,
          message: 'サインインへリダイレクトします。',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: ()=> {
                setTimeout(()=>{
                  setPresentDialog({ status: false, header: '', subHeader: '', message: '', buttons: [] });
                  // navigate('/signin/')
                }, 300)
              }
            },
          ]
        });
        console.log(error)
      }
    })
  }

  useLayoutEffect(() => {
    setProgressIndicator(true);
    const targets = document.getElementsByClassName('inputModeText');
    for(let i=0; i < targets.length; i++){
      targets[i].style.setProperty('outline', 'none', 'important');
    }
  }, []);

  useEffect(() => {
    if(methods.formState.errors.email){
      setIsEmailValidate(true);
    }else{
      setIsEmailValidate(false);
    }
    if(methods.formState.errors.displayName){
      setIsDisplayNameValidate(true);
    }else{
      setIsDisplayNameValidate(false);
    }
  }, [methods.formState]);

  useEffect(() => { 
    // setProgressIndicator(true);
    getAccessToken();
    /*
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (code) {
      setAuthCode(code)
      getAccessToken(code);
      
    } else {
      setProgressIndicator(false);
      // navigate('/signin/');
    }
    */
  }, [])

  return (
    <>
    <div
      className={`
        fixed
        top-0
        left-0
        flex
        justify-center
        items-center
        w-full
        min-h-screen
        h-full
        z-[6]
        bg-black
        bg-opacity-30
        transition-opacity
        duration-[0.4s]
        ${presentDialog.status ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
      `}
    >
      <DiaLog
        status={presentDialog.status}
        header={presentDialog.header}
        subHeader={presentDialog.subHeader}
        message={presentDialog.message}
        buttons={presentDialog.buttons}
      />
    </div>
    <div
      className={`
        fixed
        top-0
        left-0
        flex
        justify-center
        items-center
        w-full
        min-h-screen
        h-full
        z-[6]
        bg-black
        bg-opacity-30
        ${progressIndicator ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
      `}
    >
      <ProgressIndicator />
    </div>
    <section
      className="
        bg-white
        dark:bg-content-dark
        fixed
        top-0
        left-0
        flex
        justify-center
        items-center
        flex-col
        w-screen
        h-screen
        p-4 
      "
    >
      <div
        className="
          relative
          h-auto
          my-auto
          px-4
          lg:px-0
          max-h-full
          w-full
          box-border
          overflow-x-hidden
          overflow-y-auto
        "
      >
        <div
          className="
            mt-0
            mx-auto
            max-w-[360px]
            text-center
          "
        >
          <svg className="mx-auto h-[75px] w-[75px]" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 270.5   300" xmlSpace="preserve">
            <path className="fill-[#907359] dark:fill-white" d="M55.4,138.1c13.9,0,27.9,0,41.8,0c3.2,0,6.3,0.1,8.2-3.5c2-3.8,1-7.4-0.9-10.5c-2.1-3.4-5.2-6.3-8-9.2
            	c-0.7-0.8-2.4-1.6-3.2-1.3c-12.8,4.5-26,2.9-39,2.9c-5.6,0-11.3-0.7-16.6-2.3c-6.6-1.9-11.1-6.8-12.7-13.6
            	c-1.5-6.2,4.9-14.3,12.4-16.2c9.3-2.4,18,0.1,26.7,3.1c11.7,4.1,21.4,11.2,30.2,19.7c1.3,1.3,2.2,2.1,4.5,1
            	c4.7-2.2,9.7-3.8,14.7-5.4c3.5-1.1,2-2.5,0.4-3.9c-4.1-3.4-8.4-6.4-12.3-10c-6-5.7-12.2-11.4-17.4-17.8c-4.1-5-5.7-11.5-5.7-18.2
            	c0-6.9-0.3-13.7,3.2-20.1c5.5-10.2,13.7-16.1,25.4-17c3.7-0.3,6.7-1.6,9-5.1c9.4-14.7,29.5-14.1,38.7,0.1c2.2,3.4,5.2,4.8,9,5
            	c16,0.7,28.8,15.5,28.6,30.8c-0.1,7.1-0.7,14-3.7,20.6c-4,8.7-11.1,14.5-18.1,20.5c-4.4,3.8-9.1,7.2-13.5,11.1
            	c-3,2.7-2.9,2.8,0.7,4.4c1.1,0.5,2.2,1.2,3.3,1.6c4.3,1.5,8.9,4.5,12.8,3.8c3.8-0.7,6.8-5.3,10.2-8c9.1-7.3,19-13.2,30.6-15.6
            	c6.4-1.3,12.8-2.2,19.3-0.5c5.8,1.5,11.4,7.7,11.7,13.6c0.3,6-4,12.4-10,14.9c-5.9,2.4-12.1,3.6-18.5,3.6c-4.5,0-8.9-0.2-13.4,0
            	c-8.9,0.5-17.6-0.9-26.3-2.4c-1.1-0.2-2.8,0.1-3.5,0.8c-2.9,3-5.9,6.1-8.3,9.6c-2.1,3-2.2,6.6-0.3,10.1c1.6,3,4.3,3.3,7.2,3.3
            	c28.4,0,56.8,0,85.3,0c5.2,0,7.9-3.2,8.4-9.4c0.5-6.1-1.2-11.9-2.8-17.6c-4.1-14.8-10.7-28.5-19.9-40.8c-7.9-10.5-17-20-27.8-27.6
            	c-5.4-3.8-11.2-7.2-16.8-10.7c-0.6-0.4-1.3-0.6-1.8-1.1c-0.6-0.8-1-1.7-1.5-2.6c1-0.2,2.1-0.8,3-0.5c2.5,0.9,4.9,2,7.1,3.4
            	c13.4,8.1,25.4,18.2,35.5,30.1c12.4,14.7,21.6,31.3,26.3,50.2c1.3,5.3,3.3,10.4,2.9,15.9c-0.4,6.2-2.1,11.9-9,14
            	c-1.5,0.5-3,0.9-4.6,0.9c-27.7,0.1-55.4,0.1-83.1,0c-4.5,0-8.9-1-11.7-5.6c-2.9-4.7-2.5-9.4,1.2-15.2c1.6-2.5,3.7-4.7,5.8-6.9
            	c1.8-1.9,1.6-2.6-1-3.4c-5.1-1.7-10.2-3.5-15.2-5.7c-2.6-1.1-4.3-0.7-6.1,1.3c-2.3,2.5-4.7,4.9-7.2,7.2c-1.7,1.5-1.6,2.8-0.6,4.5
            	c3.1,5.3,5.2,11,6.6,17c2.2,9.5,2.2,18.8,0.2,28.3c-1.3,6.2-2.9,12.2-6.5,17.6c-0.6,1,0.1,3.4,0.9,4.7c1.4,2,3.7,3.4,5.2,5.4
            	c2.9,3.8,6.1,4,10.2,2.1c3.9-1.9,8-3.4,12.1-5c3.4-1.3,3.3-1.1,1.1-3.8c-2.6-3.3-5.7-6.4-7.4-10.2c-3.9-8.9,1.8-16.5,11.4-17.1
            	c1.1-0.1,2.1,0,3.2,0c26.3,0,52.6,0.2,78.8-0.1c9.9-0.1,15.9,5.4,15.6,15.6c-0.2,6.2-1.8,12.5-3.5,18.5c-4.2,15-11.3,28.8-20.7,41
            	c-10.6,13.7-22.9,25.8-38.1,34.5c-3,1.7-6,3.6-9.2,5.1c-0.8,0.4-2.2-0.4-3.3-0.7c0.6-0.9,0.9-2,1.7-2.5c2.2-1.4,4.6-2.5,6.9-3.8
            	c12.9-7.1,23.5-17.1,33.1-28c5.3-6,9.9-12.8,13.8-19.8c4.3-7.7,7.9-15.9,11.1-24.2c2.6-6.7,4.4-13.7,4.1-21.1
            	c-0.4-7.4-3-10.2-10.4-10.2c-27.5,0-55.1,0-82.6,0c-3.2,0-6.4-0.1-8.1,3.6c-1.6,3.6-1.5,7,0.6,10.1c2.3,3.3,5.2,6.3,8,9.1
            	c0.9,0.9,2.9,1.7,4,1.3c8.1-2.4,16.3-3.1,24.6-2.7c8.3,0.3,16.7,0.3,25,1.2c5.2,0.6,10.1,2.4,13.9,6.6c7.1,7.9,4.7,17-2.6,21.8
            	c-5,3.3-10.8,4.1-17.1,3.1c-7.9-1.3-15.4-3-22.5-6.7c-8-4.2-15.5-9.1-21.7-15.6c-2.4-2.6-4.6-2.6-7.3-1.6c-4.6,1.7-9.1,3.5-13.7,5.3
            	c-1.8,0.7-1.9,1.8-0.4,3c4.8,3.9,9.5,7.9,14.3,11.8c9.8,8,18.9,16.6,21.1,29.9c1.9,11.4,1,22.3-6.7,31.5c-5.2,6.2-12.1,10-20.5,10.6
            	c-4.1,0.3-7.3,1.4-9.8,5.3c-7.7,11.7-24.2,15-35.4,3.6c-1.1-1.1-2.4-2.3-3.3-3.5c-3.3-4.2-3.7-4.7-9.6-5.4
            	c-15.9-1.5-28.1-15.9-27.8-31.1c0.1-6.9,0.1-13.8,3.3-20.1c1.4-2.8,2.8-5.7,4.9-8c7.5-8,15.4-15.6,24.6-21.7
            	c0.9-0.6,1.6-1.4,2.3-2.2c2.8-2.8,2.9-2.9-0.9-4.4c-4-1.6-8.1-3.2-12.3-4.6c-2.9-1-5.2-0.6-7.8,1.9c-9.3,8.7-19.5,16.1-32,19.5
            	c-7.4,2-14.8,4.1-22.7,2c-6.8-1.8-11.1-5.8-12.8-12.5c-1.4-5.5,3.3-13.6,10.1-16.4c5.9-2.4,12.2-3.2,18.5-3.4
            	c6.1-0.2,12.2-0.3,18.2,0c6.7,0.4,13.3,1.1,19.8,2.5c2.6,0.5,3.9,0.2,5.5-1.5c3.5-3.7,7.2-7.3,8.8-12.3c1.8-5.9-1.5-10.2-7.7-10.2
            	c-28.2,0-56.5,0-84.7,0c-5.9,0-9,2.9-9,9.2c0,4.6,0.3,9.2,1.3,13.7c1.4,5.7,3.6,11.3,5.7,16.9c4.5,12.1,11.1,23.2,19.4,32.9
            	C38,242.8,46.5,250,55,257c5,4.2,11.1,7.2,16.8,10.8c0.3,0.2,0.8,0.1,1,0.4c0.8,1.1,1.6,2.2,2.4,3.3c-1.4,0-3.2,0.6-4.3,0
            	c-6.6-3.9-13.5-7.5-19.6-12.2c-13.2-10.2-24.5-22.2-33.3-36.4c-8.7-13.9-14.6-29.1-17.5-45.2c-1.5-8,0.7-17,9-19.3
            	c1.5-0.4,3-0.9,4.6-0.9c27.7-0.1,55.4-0.1,83.1,0c5.6,0,10.1,2.1,12.6,7.6c2.3,5.3,0.2,9.5-2.7,13.7c-1,1.4-2.1,2.8-3.1,4.2
            	c-1.2,1.6-2.5,3.3-3.7,4.9c2,0.7,3.9,1.3,5.9,2c4.1,1.5,8.2,3,12.3,4.6c2.1,0.8,3.8,0.6,5.3-1.2c0.3-0.4,0.8-0.8,1.1-1.1
            	c2.4-2.6,6.2-4.8,6.9-7.9c0.6-2.6-2.3-5.9-3.5-9c-3.8-10.2-5.7-20.8-4.5-31.7c0.9-8.3,2.6-16.7,7-24c1.8-3,1.5-5.2-1.2-7.3
            	c-1.3-1-2.3-2.2-3.4-3.4c-5-5-5-5-11.4-2.3c-3.9,1.6-7.7,3.4-11.7,4.5c-3,0.8-3.1,1.5-1.1,3.7c2.3,2.6,4.3,5.5,6.3,8.4
            	c5.8,8.2,1.2,17.5-9.3,19.1c-1.2,0.2-2.5,0-3.7,0c-26.5,0-52.9-0.1-79.4,0.1c-9.7,0.1-15.1-5.3-15.5-15.3c-0.4-7.7,2.3-14.9,4.7-22
            	c6.8-19.7,17.2-37.1,32.2-51.9c9.6-9.5,20.1-17.7,31.9-24.1c1.8-1,3.8-3,6.1-0.7c-1.5,1.4-2.7,3.2-4.4,4.1
            	c-15.9,8.3-28.8,20.2-40.2,33.9C18.6,80.7,11.1,97.2,6.1,114.8c-1.6,5.7-2.9,11.7-0.6,17.7c1.7,4.5,3,5.6,7.6,5.6
            	C27.2,138.1,41.3,138.1,55.4,138.1z M155.6,243.7c-2.7-12.3-9.2-22.6-17.2-32.1c-2.3-2.8-3.9-2.9-6.2,0.1c-3.8,4.8-7.3,9.8-10.6,15
            	c-5.3,8.5-7.6,18.1-8.6,27.9c-0.8,7.5-0.7,15.1,1.7,22.6c0.9,2.9,2.2,2.9,4.2,2.1c6.8-2.5,11.6-7,14.3-13.8c0.4-1,1.3-1.9,2-2.8
            	c0.8,1,1.8,1.9,2.3,3c3,6.4,7.3,11.5,14.4,13.7c2.1,0.7,3.7,0.6,4.1-2.3C157.8,262.8,158.6,260.1,155.6,243.7z M158.1,37
            	c-0.5-3.5-1.1-8.6-1.9-13.6c-0.4-2.8-1.7-3.9-4.7-2.8c-6.9,2.5-11.3,7.4-14,14c-0.4,1-1.4,1.8-2.1,2.6c-0.7-0.9-1.7-1.7-2.1-2.7
            	C130.8,28.6,127,24,121,21.5c-4.9-2.1-5.7-2.7-6.9,3.5c-1.6,7.8-1.4,15.6-0.3,23.7c1.9,13.8,7.1,25.9,16.2,36.4
            	c5.5,6.3,5.3,6.3,10.9-0.1C152.4,71.6,157.2,55.9,158.1,37z M110.2,21.9c0.8-2.5-0.6-3.1-2.7-2.7c-0.8,0.2-1.6,0.8-2.5,0.9
            	C93.7,22,86.4,28.7,83.4,39.6c-1.3,4.7-1.2,9.8-0.8,14.9c0.7,7.9,3.5,14.4,8.9,20.1c7.7,8.1,16.5,14.9,25.2,21.8
            	c4.8,3.8,4.8,3.8,10.4,0.1c4.6-3,4.5-3,1.3-6.6c-1.3-1.4-2.4-3-3.5-4.6c-10.4-13.7-15.8-29.1-16.3-46.2
            	C108.5,33.4,109.6,27.6,110.2,21.9z M121.6,200c-0.9,0.7-1.5,1.3-2.2,1.8c-9.7,6.9-18.7,14.4-27.1,22.8
            	c-10.4,10.3-11.6,22.8-8.6,35.9c2.5,11.1,10.3,17.4,21.7,19.3c6,1,6.1,1.1,4.6-4.7c-2.8-11.1-1.4-22.1,1.3-32.9
            	c3.2-12.6,9.6-23.6,18.2-33.4c0.7-0.8,1.3-2.7,1-2.9C127.6,203.8,124.7,202,121.6,200z M187.8,49.8c1-14.2-4.9-23.4-17.2-28.6
            	c-2.1-0.9-4.3-1.2-6.5-1.7c-3.8-0.9-4.7,0.1-4,4c0.8,4.3,1.9,8.6,2,12.9c0.1,8.4-1.5,16.7-3.8,24.8c-2.9,10.1-8.6,18.7-14.6,27.1
            	c-1.4,2-2.8,4-4.3,5.9c2.2,1.4,4.5,2.7,6.7,4.1c1.8,1.1,3.2,1.3,5.2-0.2c10.6-8,21-16.1,30-25.9c0.7-0.8,1.1-1.7,1.7-2.6
            	C186.7,63.3,188.8,56.5,187.8,49.8z M188.1,250.8c0-8.3-1.6-16.9-7.1-23.4c-8.4-9.9-18.7-17.7-29.2-25.2c-3.3-2.4-7.7-1-12.1,3.5
            	c-0.2,0.2-0.5,0.4-0.8,0.5c1.2,1.3,2.4,2.6,3.5,4c0.7,0.8,1,1.8,1.7,2.6c12,13.7,16.8,30.1,17.9,47.8c0.3,4.4-0.6,8.8-1,13.2
            	c-0.2,2.2-0.3,4.4-0.5,6.5c2.2-0.3,4.5-0.2,6.6-0.8c6-1.7,11.4-4.4,15.3-9.6C186.8,264.2,187.9,259.4,188.1,250.8z M201.1,113.5
            	c8.9-0.4,15.7-0.5,22.3-1.2c3.7-0.4,7.5-1.6,11-3c5-2.1,7.4-6.3,7.1-11.3c-0.3-4.4-3.1-7.3-8.2-9.2c-6.5-2.5-13-0.9-19.3,0.5
            	c-12.6,2.8-22.9,10.1-32.6,18.3c-0.7,0.6-2,2.2-2.5,2.9c1.1,0.4,2.5,0.5,3.4,0.6C189.3,112.1,196.3,112.9,201.1,113.5z M225.8,212.8
            	c3.2-0.8,6.5-1.3,9.5-2.6c7-3,8.4-10.9,3.3-16.6c-2.8-3.1-6.2-4.5-10.1-5c-4.7-0.6-9.6-0.4-14.2-1.2c-7.4-1.4-14.7-1.1-22.1,0.1
            	c-3.4,0.5-6.8,1.5-10.2,1.9c-2.7,0.3-2.5,1.3-0.8,2.6c5.2,3.9,10.3,7.8,15.7,11.4C205.6,209.1,215.3,211.7,225.8,212.8z M45.7,212.6
            	c3.8-0.8,7.9-1.6,11.9-2.5c12.1-2.5,21.4-10,30.9-17.2c0.8-0.6,1.6-1.8,1.5-2.5c-0.1-0.7-1.4-1.6-2.3-1.7c-6.3-0.8-12.5-2-18.8-2.1
            	c-7.2-0.2-14.5,0.3-21.7,1.1c-3.7,0.4-7.5,1.6-11,3.1c-5,2.1-7.4,6.4-6.9,11.3c0.5,5,3.6,7.9,8.3,9C40,211.7,42.6,212,45.7,212.6z
            	 M67.4,113.4c8.2,0,13.4-1.3,20-2.3c1-0.2,1.9-1.3,2.8-2c-0.6-0.8-1.1-1.9-1.9-2.4c-4.9-3.6-9.7-7.5-15-10.6
            	c-9.8-5.9-20.6-9.2-32.1-8.2c-8.9,0.8-12.8,6-12,12.4c0.7,5.3,4.3,8.3,9,9.9C47.7,113.5,57.5,112.9,67.4,113.4z M127.4,149.9
            	c0.5,4.7,0.6,9.5,1.6,14.2c0.9,4.4,2.6,8.7,4.3,12.8c1.1,2.6,2.6,2.5,4.1,0c0.5-0.9,0.8-1.9,1.1-2.9c3.5-8.6,4.6-17.6,4.3-26.9
            	c-0.3-8.2-2-15.9-5.6-23.3c-0.6-1.2-1.5-2.3-2.3-3.4c-0.7,1.1-1.4,2.2-1.9,3.4C129.2,132,127.3,140.7,127.4,149.9z M135.1,295.8
            	c6.6,0,13.3-3.4,16.2-8.2c1.1-1.9,1.8-3.7-1.4-4.8c-5.9-1.9-10.2-5.8-13.5-11.1c-0.5-0.8-1.1-2.2-2.4,0c-3.3,5.8-8.2,10-14.8,11.8
            	c-2.2,0.6-1.6,2.1-0.8,3C122.7,291.8,127.6,296.3,135.1,295.8z M153.2,15.4c-0.4-0.5-0.8-0.9-1-1.4c-2.1-4.1-5.7-6.5-9.7-8.5
            	c-8.2-4.1-19,0.4-23.3,7.1c-1.2,1.9-1.5,3.3,1.2,4.5c5.4,2.3,10.5,5.1,13.4,10.6c0.2,0.4,2.6,0.5,2.9,0c3.6-5.9,8.9-9.3,15.1-11.6
            	C152.3,15.9,152.7,15.6,153.2,15.4z M135.3,187.5c-0.4,0.2-0.8,0.3-1,0.6c-2.7,2.6-5.4,5.2-7.9,8c-0.4,0.4-0.1,2.1,0.5,2.6
            	c5.7,5.1,9,5.2,15.3,0.7c0.6-0.4,1.3-0.7,1.7-1.2c0.4-0.5,0.8-1.7,0.5-2c-2.5-2.7-5.2-5.4-7.9-8
            	C136.2,187.9,135.7,187.7,135.3,187.5z M135.4,112.3c0.3-0.1,0.7-0.2,0.9-0.4c2.8-2.8,5.5-5.5,8.2-8.4c0.2-0.2,0-1.4-0.4-1.7
            	c-2.9-1.9-5.8-4.4-9-5.1c-1.9-0.4-4.4,2.3-6.6,3.7c-0.7,0.4-1.6,0.8-2.1,1.4c-0.3,0.4-0.5,1.4-0.2,1.7c2.7,2.8,5.5,5.6,8.3,8.3
            	C134.7,112.1,135.1,112.2,135.4,112.3z"/>
          </svg>
        </div>
        <h1
          className="
            block
            text-[#391f0d]
            dark:text-white
            text-[26px]
            text-center
            font-thin
            leading-none
            tracking-[0.011em]
            mt-5
            mb-[10px]
            uppercase
          "
        >
          REALITY WEDDING
          <small
            className="
              block
              text-[#494949]
              dark:text-[#8e8d93]
              text-xs
              font-light
              pt-[10px]
              capitalize
            "
          >
            WEDDING COURT EMILIA
          </small>
        </h1>
        <FormProvider {...methods} >
        <form className='max-w-[360px] my-0 mx-auto' onSubmit={methods.handleSubmit(createMeeting)}>
          <ul>
            <li className='relative box-border'>
              <Input
                name='email'
                type='email'
                inputMode='text'
                placeholder='メールアドレス'
                disabled={progressIndicator}
                isValidate={isEmailValidate}
                style=''
                onFocus={() => {
                  setIsEmailFocus(true);
                  setCreateError('');
                }}
                rules={{
                  onBlur: () => setIsEmailFocus(false),
                  required: {
                    value: true,
                    message: 'メールアドレスを入力してください。',
                  },
                  pattern: {
                    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                    message: "正しいメールアドレス形式で入力してください。"
                  }
                }}
              />
              {methods.formState.errors.email && <FormError message={String(methods.formState.errors.email.message)} />}
            </li>
            <li className='relative box-border mt-[9px]'>
              <Input
                name='displayName'
                type='text'
                inputMode='text'
                placeholder='お名前'
                disabled={progressIndicator}
                isValidate={isDisplayNameValidate}
                style={{'ontline': 'none !important'}}
                onFocus={() => {
                  // methods.clearErrors();
                  setIsDisplayNameFocus(true);
                  setCreateError('');
                }}
                rules={{
                  onBlur: () => setIsDisplayNameFocus(false),
                  required: {
                    value: true,
                    message: 'お名前を入力してください。',
                  },
                }}
              />
              {methods.formState.errors.displayName && <FormError message={String(methods.formState.errors.displayName.message)} />}
            </li>
            <li className="mt-[13px]">
              <button
                className={`
                  text-gray-100
                  
                  font-bold 
                  py-3 
                  px-4 
                  rounded-md
                  w-full
                  h-[44px]
                  text-center
                  duration-200
                  ease-in
                  ${methods.formState.isValid
                    ? 'bg-[#0e71eb] hover:bg-[#2681f2] cursor-pointer text-opacity-100 hover:text-white'
                    : 'bg-[#c8c8c8] dark:bg-[#323137] cursor-default text-opacity-60 dark:text-opacity-30'
                  }
                `}
                disabled={methods.formState.isValid ? '' : 'disabled'}
              >
                ミーティングを作成
              </button>
            </li>
          </ul>
        </form> 
        </FormProvider>
      </div>
    </section>
    </>
  );
};

/**
 * メッセージを流す
 */
const createText = (text) => {
  const randomNum = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  var id = 'video-share-layout'
  var textbox_element = document.getElementsByClassName(id)[0];

  const height = textbox_element.style.height.replace('px', '')
  var new_element = document.createElement('p')
  new_element.textContent = text
  new_element.className = 'barrage'
  new_element.style.cssText = `top: ${randomNum(0, height)}px;`
  textbox_element.appendChild(new_element);
}

/**
 * チャットパネルを開く
 *
 * チャットメッセージのDOMを取得するため
 */
const showChatPane = () => {
  const chatBtn = document.querySelector('[aria-label="open the chat pane"]');
  chatBtn.click()
  document.getElementById('wc-container-left').style.cssText = 'width: 100%;'
}

/**
  * 弾幕開始
  */
function fire() {
  createText(document.getElementById('fireInput').value)
  document.getElementById('fireInput').value = ''
  /*
  let lastMessage = null
  setInterval(() => {
    let chatMessages = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')[0].children
    for(let i = chatMessages.length -1 ; i >= 0 ; i-- ) {
        if (lastMessage && lastMessage.id === chatMessages[i].id) {
            break
        }
        const text = chatMessages[i].lastElementChild.textContent
        createText(text)
    }
    lastMessage = chatMessages[chatMessages.length - 1]
  }, 1000)
  */
}