import { useContext, useEffect, useRef } from "react"
import ProgressIndicator from '../progressIndicator/';

const Dialog = (props) => {
  const element = useRef()

  useEffect(() => {
    
    if(element.current){
      if(props.status){
        element.current.animate({
          opacity: [ 0,  1 ],
          transform: ["scale(1.185)", "scale(1)"]
        }, {
          duration: 200,
        });
      }else{

        element.current.animate({
          opacity: [ 1,  0 ]
        }, {
          duration: 200,
         });
      }

    }
  }, [props.status]);

  return (
    <div
      ref={element}
      className={`
        rounded-[13px]
        overflow-hidden
        shadow-none
        flex
        flex-col
        w-auto
        min-w-[250px]
        max-w-[270px]
        h-auto
        max-h-[90%]
        bg-[#f9f9f9]
        dark:bg-[#252525]
        z-[9]
      `}
    >
      <div className="
        px-4
        pt-3
        pb-[7px]
        text-center
      ">
        <h2 className="
          text-[17px]
          font-semibold
          p-0
          mt-2
          mx-0
          mb-0
        ">
          {props.header}
        </h2>
        <h2 className="
          text-[14px]
          text-[#666666]
          p-0
          mt-[5px]
          mx-0
          mb-0
        ">
          {props.subHeader}
        </h2>
      </div>
      <div className="
        text-center
        max-h-[240px]
        px-4
        pt-0
        pb-[21px]
        break-words
        text-[13px]
      ">
        {props.message}
      </div>
      <div className="
        flex
        flex-row
        flex-wrap
        w-full
        mr-[-0.55px]
      ">
        {
          props.buttons.map((button, index) => {
            return (
              <button
                key={button.text}
                type="button"
                disabled={props.isFetcher}
                className={`
                  ${button.role == "confirm" ? "font-bold" : ""}
                  bg-transparent
                  m-0
                  rounded-none
                  flex-auto
                  min-w-[50%]
                  h-[44px]
                  border-t-[0.55px]
                  ${index > 0 ? " border-l-[0.55px]" : ""}
                  border-black
                  border-opacity-20
                  dark:border-[#656565]
                  dark:border-opacity-100
                  text-[17px]
                  text-primary
                  dark:text-white
                  overflow-hidden
                  ${props.isFetcher
                    ? ""
                    : "hover:bg-black hover:bg-opacity-10 dark:hover:bg-[#434345] dark:hover:bg-opacity-100"
                  }
                `}
                onClick={button.handler}
              >
                <span className="
                  flex
                  shrink-0
                  items-center
                  justify-center
                  w-full
                  h-full
                  pointer-events-none
                ">
                  {button.role == "confirm" && props?.isFetcher
                  ? <div className="relative w-full">
                      <ProgressIndicator />
                    </div>
                  : button.text
                  }
                </span>
              </button>
            )
          })
        }
      </div>
    </div>
  )
}

export default Dialog