import { forwardRef, useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const Input = forwardRef((props, ref)=>{
  const { register } = useFormContext();
  return (
    <input
      type={props.type}
      inputMode={props.inputMode}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      onFocus={props?.onFocus}
      className={`
        inputModeText
        h-10
        rounded-md
        border
        border-solid
        border-[#d6d6d6]
        py-2
        px-[15px]
        bg-transparent
        text-[17px]
        w-full
        placeholder:text-[#888888]
        focus:border-[#2681f2]
        dark:text-white
        dark:focus:border-[#2681f2]
        dark:border-[#3d3c41]
        dark:placeholder:text-[#5a595e]
        ${ props.isValidate ? 'border-[#ff3b30] dark:border-[#ff3b30]' : '' }
      `}
      {...register(props.name, props?.rules)}
    />
  )
})

Input.displayName = 'Input'
export default Input