const ProgressIndicator = () => {
  
  return (
    <div
      className="
        relative
        top-0
        left-0
        text-[0]
        flex
        justify-center
        items-center
        w-full
        h-full
      "
    >
  	  <div
        className='
          block
          relative
          animate-spin2
        '
        style={{"width": "20px", "height": "20px"}}
      >
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-0
            opacity-[0.27]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[45deg]
            opacity-[0.35285714]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[90deg]
            opacity-[0.43571429]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[135deg]
            opacity-[0.51857143]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[180deg]
            opacity-[0.60142857]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[225deg]
            opacity-[0.68428571]
            bg-[#6c6c6c]
            dark:bg-white
          `}
        />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[270deg]
            opacity-[0.76714286]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
        <span
          className={`
            block
            w-[11.6%]
            h-[33.33%]
            rounded-[100px]
            absolute
            left-1/2
            top-1/2
            origin-[50%_150%]
            -translate-x-1/2
            -translate-y-[150%]
            rotate-[315deg]
            opacity-[0.85]
            bg-[#6c6c6c]
            dark:bg-white
        `} />
      </div>
    </div>
  );
};

export default ProgressIndicator